import React, { useState } from 'react';
import { array, arrayOf, bool, func, shape, string, oneOf, object } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import testImage from '../../assets/biketribe-brandImage-1500.jpg'
import Slider from 'react-slick';

// Contexts
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
// Utils
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { LISTING_STATE_PENDING_APPROVAL, LISTING_STATE_CLOSED, propTypes, BRAND, ATHLETE, PROMOTION } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  NO_ACCESS_PAGE_USER_PENDING_APPROVAL,
  NO_ACCESS_PAGE_VIEW_LISTINGS,
} from '../../util/urlHelpers';
import {
  isErrorNoViewingPermission,
  isErrorUserPendingApproval,
  isForbiddenError,
} from '../../util/errors.js';
import { hasPermissionToViewData, isUserAuthorized } from '../../util/userHelpers.js';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { richText } from '../../util/richText';
import {
  isBookingProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';

// Global ducks (for Redux actions and thunks)
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';

// Shared components
import {
  H4,
  Page,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  LayoutSingleColumn,
  AvatarLarge,
  SecondaryButton,
  Button,
  ExternalLink,
  Modal,
  ResponsiveImage,
} from '../../components';

// Related components and modules
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';

import {
  sendInquiry,
  setInitialValues,
  fetchTimeSlots,
  fetchTransactionLineItems,
} from './ListingPage.duck';

import {
  LoadingPage,
  ErrorPage,
  priceData,
  listingImages,
  handleContactUser,
  handleSubmitInquiry,
  handleSubmit,
  priceForSchemaMaybe,
} from './ListingPage.shared';
import SectionHero from './SectionHero';
import SectionTextMaybe from './SectionTextMaybe';
import SectionReviews from './SectionReviews';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionMapMaybe from './SectionMapMaybe';
import CustomListingFields from './CustomListingFields';

import css from './ListingPage.module.css';
import { getUserType } from '../../util/helper.js';
import { formatMoney } from '../../util/currency.js';
import { termsAndConditionsData, termsAndConditionsDataFi } from '../../config/configListing.js';
import moment from 'moment';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';
import classNames from 'classnames';
import IconCollection from '../../components/IconCollection/IconCollection.js';
import AboutListingSection from './AboutListingSection.js';
import InterestListingSection from './InterestListingSection.js';
import SponsorsListingSlider from './SponsorsListingSlider.js';
const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;

const { UUID, Money } = sdkTypes;

export const ListingPageComponent = props => {
  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );

  const [imageCarouselOpen, setImageCarouselOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleImageClick = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const {
    id,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    onFetchTransactionLineItems,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    showOwnListingsOnly,

  } = props;
  const userType = getUserType(currentUser);
  const listingConfig = config.listing;
  const listingId = id || new UUID(rawParams.id);

  const isVariant = rawParams.variant != null;
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant || showOwnListingsOnly
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const dispatch = useDispatch()
  // Check fav 
  const isFavorite = currentUser?.attributes.profile.privateData.favorites?.includes(listingId.uuid);
  const handleToggleFavorites = (isFavorite) => {
    const { attributes: { profile } } = currentUser;
    const { favorites = [] } = profile.privateData || {};

    let payload;

    if (!profile.privateData || !profile.privateData?.favorites) {
      payload = {
        privateData: {
          favorites: [listingId.uuid],
        },
      };
    } else if (isFavorite) {
      payload = {
        privateData: {
          favorites: favorites.filter(f => f !== listingId.uuid),
        },
      };
    } else {
      payload = {
        privateData: {
          favorites: [...favorites, listingId.uuid],
        },
      };
    }
    dispatch(updateProfile(payload))

  };


  const favoriteButton = isFavorite ? (
    <Button
      className={classNames(css.favoriteButton)}
      onClick={() => handleToggleFavorites(isFavorite)}
    >
      <IconCollection name="love_icon" />
    </Button>
  ) : (
    <Button className={classNames(css.favoriteButton, css.notActiveFavorite)} onClick={() => handleToggleFavorites(isFavorite)}>
      <IconCollection name="love_icon" />
    </Button>
  );

  const CopyTextOnClipBoard = (URL, tooltipIdLink) => {
    if (window && window.navigator && navigator.clipboard && navigator.clipboard.writeText) {
      const rootUrl = process.env.REACT_APP_MARKETPLACE_ROOT_URL 
      const newURL = `${rootUrl}/l/${URL}`;
      navigator.clipboard.writeText(newURL);
      const btn = document.getElementById(tooltipIdLink);
      btn.style.backgroundColor = 'green';
      setTimeout(() => {
        btn.style.backgroundColor = 'white';
      }, 1000);
    }
  }


  const user = currentListing?.author;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const topbar = <TopbarContainer />;

  if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage staticContext={props.staticContext} />;
  } else if (showListingError) {
    // Other error in fetching listing
    return <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  } else if (!currentListing.id) {
    // Still loading the listing
    return <LoadingPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} />;
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;
  const { images } = currentListing || [];

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );
  const {
    listingType,
    transactionProcessAlias,
    unitType,
    brandDescription,
    companyName,
    athleteDescription,
    teamName,
    location: userLocation,
    lifestyleInterest,
    sports,
    brandSponsorshipType,
    brand_ambassadorPrice,
    logo_visibilityPrice,
    social_mediaPrice,
    appearancePrice,
    eventsPrice,
    selectedTermsAndConditions,
    activeIcons,
    timeDuration_right_to_image,
    timeDuration_must_not_delete_social_media_post_before,
    preferenceType,
    appearanceDescription,
    brand_ambassadorDescription,
    eventsDescription,
    logo_visibilityDescription,
    social_mediaDescription,
    brandPrice,
    eventDate,
    eventsName,
    priceShow,
    shareProfile,
    goals,
    achievementsrecords,
    promotionCode, 
    promotionDetails,
    promotionHeading,
    promotionTerms,
    promotionTitle,
    websiteLink,  
    brandImages
  } = publicData || {};   
  
  const isShowPrice = priceShow === "yes" ? true : false;
  const language = typeof window != 'undefined' && localStorage.getItem('language');
  const selectedTermsAndConditionsData =
    language === 'Finnish' ? termsAndConditionsDataFi : termsAndConditionsData;
  const termsAndConditions = selectedTermsAndConditionsData
    .filter(data => selectedTermsAndConditions?.includes(data?.key))
    .map(data => data?.label);

  const termsAndConditionMonths = selectedTermsAndConditionsData?.[0]?.data.map(itm => itm);
  const result = termsAndConditionMonths.find(item => item.key === timeDuration_right_to_image);
  const monthslabel = result ? result.label : null;

  // lifestyleInterest
  const lifestyleInterest_values = Array.isArray(lifestyleInterest) && lifestyleInterest.length > 0
    ? lifestyleInterest.map(item => {
      return intl.formatMessage({ id: `enum.${item}` });
    })
    : [];

  const lifestyle_Interest = lifestyleInterest_values.length > 0
    ? lifestyleInterest_values.join(', ')
    : null;

  // sports
  const translatedSports = Array.isArray(sports) && sports.length > 0
    ? sports.map(item => intl.formatMessage({ id: `enum.${item}` }))
    : [];

  const sportsLabel = translatedSports.length > 0
    ? translatedSports.join(', ')
    : null;

  // preferenceType
  const translatedPreferenceType = Array.isArray(preferenceType) && preferenceType.length > 0
    ? preferenceType.map(item => intl.formatMessage({ id: `enum.${item}` }))
    : [];

  const preferenceTypeLabel = translatedPreferenceType.length > 0
    ? translatedPreferenceType.join(', ')
    : null;

  // brandSponsorshipType
  const sponsorTypes = brandSponsorshipType ? intl.formatMessage({ id: `enum.${brandSponsorshipType}` }) : null;

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  const authorDisplayName = ensuredAuthor?.attributes?.profile?.publicData?.userName;
  const companyDisplayName = ensuredAuthor?.attributes?.profile?.publicData?.companyName;
  const { formattedPrice } = priceData(
    brandPrice ? new Money(brandPrice * 100, price.currency) : price,
    config.currency,
    intl
  );
  const logoPriceF = logo_visibilityPrice ? new Money(logo_visibilityPrice, config.currency) : null;
  const formattedLogoVisibilityPrice = logoPriceF ? formatMoney(intl, logoPriceF) : null; 
  
  const postPriceF = social_mediaPrice ? new Money(social_mediaPrice, config.currency) : null;
  const formattedSocialMediaPrice = postPriceF ? formatMoney(intl, postPriceF) : null;

  const appearancePriceF = appearancePrice ? new Money(appearancePrice, config.currency) : null;
  const formattedApperancePrice = appearancePriceF ? formatMoney(intl, appearancePriceF) : null;

  const ambassadorPriceF = brand_ambassadorPrice
    ? new Money(brand_ambassadorPrice, config.currency)
    : null;
  const formattedBrandAmbassadorPrice = ambassadorPriceF
    ? formatMoney(intl, ambassadorPriceF)
    : null;

  const eventsPriceF = eventsPrice ? new Money(eventsPrice, config.currency) : null;
  const formattedEventsPrice = eventsPriceF ? formatMoney(intl, eventsPriceF) : null;




  const priceArray = [
    { key: 'logo_visibilityPrice', label: 'Logo', price: logoPriceF },
    { key: 'social_mediaPrice', label: 'Social Media', price: postPriceF },
    { key: 'brand_ambassadorPrice', label: 'Brand Ambassador', price: ambassadorPriceF },
    { key: 'appearancePrice', label: 'Appearance', price: appearancePriceF },
    { key: 'eventsPrice', label: 'Event', price: eventsPriceF },
  ];

  const priceArrayfi = [
    { key: 'logo_visibilityPrice', label: 'Logonäkyvyys', price: logoPriceF },
    { key: 'social_mediaPrice', label: 'Sosiaalinen media', price: postPriceF },
    { key: 'brand_ambassadorPrice', label: 'Brändilähettiläs', price: ambassadorPriceF },
    { key: 'appearancePrice', label: 'Esiintyminen', price: appearancePriceF },
    { key: 'eventsPrice', label: 'Tapahtuma', price: eventsPriceF },
  ];


  const handleModal = (price, key) => {
    if (price && price.amount && key) {
      const initialValues = {
        deliverableItems: [key],
        partnershipStartLength: [],
        partnershipLength: '',
        inquiryPrice: price,
      };

      setInquiryModalOpen(initialValues);
    }
  };




  const priceArraydynamic = language === 'Finnish' ? priceArrayfi : priceArray;

  if (!(listingType && transactionProcessAlias && unitType)) {
    // Listing should always contain listingType, transactionProcessAlias and unitType)
    return (
      <ErrorPage topbar={topbar} scrollingDisabled={scrollingDisabled} intl={intl} invalidListing />
    );
  }
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isBooking = isBookingProcess(processName);
  const isPurchase = isPurchaseProcess(processName);
  const processType = isBooking ? 'booking' : isPurchase ? 'purchase' : 'inquiry';

  // const currentAuthor = authorAvailable ? currentListing.author : null;
  // const ensuredAuthor = ensureUser(currentAuthor);
  const noPayoutDetailsSetWithOwnListing =
    isOwnListing && (processType !== 'inquiry' && !currentUser?.attributes?.stripeConnected);
  const payoutDetailsWarning = noPayoutDetailsSetWithOwnListing ? (
    <div>
      <FormattedMessage id="ListingPage.payoutDetailsWarning" values={{ processType }} />
      <NamedLink name="StripePayoutPage">
        <FormattedMessage id="ListingPage.payoutDetailsWarningLink" />
      </NamedLink>
    </div>
  ) : null;

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function

  // const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');
  // const { formattedPrice } = priceData(price, config.currency, intl);

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });

  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = values => {
    const isCurrentlyClosed = currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit(values);
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title, price: formattedPrice, marketplaceName }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;
  const currentStock = currentListing.currentStock?.attributes?.quantity || 0;
  const schemaAvailability = !currentListing.currentStock
    ? null
    : currentStock > 0
      ? 'https://schema.org/InStock'
      : 'https://schema.org/OutOfStock';

  const availabilityMaybe = schemaAvailability ? { availability: schemaAvailability } : {};

  const handleViewPhotosClick = e => {
    // Stop event from bubbling up to prevent image click handler
    // trying to open the carousel as well.
    e.stopPropagation();
    setImageCarouselOpen(true);
  };

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        description: description,
        name: schemaTitle,
        image: schemaImages,
        offers: {
          '@type': 'Offer',
          url: productURL,
          ...priceForSchemaMaybe(price, intl),
          ...availabilityMaybe,
        },
      }}
    >
      <LayoutSingleColumn className={css.pageRoot} topbar={topbar} footer={<FooterContainer />}>
        {listingType == BRAND ? (
          <div className={css.mainTopWrapper}>
            <div className={css.sectionHeroWrapper}>
              <SectionHero
                isAuthenticated={isAuthenticated}
                title={title}
                listing={currentListing}
                currentUser={currentUser}
                isOwnListing={isOwnListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
                imageCarouselOpen={imageCarouselOpen}
                onImageCarouselClose={() => setImageCarouselOpen(false)}
                handleViewPhotosClick={handleViewPhotosClick}
                onManageDisableScrolling={onManageDisableScrolling}
              />
              <div className={css.avatarWrapper}>
                <AvatarLarge user={currentListing?.author} className={css.avatarDesktop} />
                <div className={css.userName}>{companyDisplayName}</div>
              </div>

              <div className={css.mobileButtonWrapper}>
                {/* athleteDescription */}
                {/* athleteDescription */}
                <SectionAuthorMaybe
                  title={"title"}
                  className={css.sectionAuthor}
                  listing={currentListing}
                  authorDisplayName={authorDisplayName}
                  onContactUser={onContactUser}
                  listingType={listingType}
                  userType={userType}
                  config={config}
                  intl={intl}
                  priceArray={priceArraydynamic}
                  isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
                  onCloseInquiryModal={() => setInquiryModalOpen(false)}
                  sendInquiryError={sendInquiryError}
                  sendInquiryInProgress={sendInquiryInProgress}
                  onSubmitInquiry={onSubmitInquiry}
                  currentUser={currentUser}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
                <div>
                  <NamedLink
                    className={css.viewProfileButton}
                    name="ProfilePage"
                    params={{ id: ensuredUser && ensuredUser?.id?.uuid }}
                  >
                    <FormattedMessage id="UserCard.viewProfileLink" />
                  </NamedLink>
                </div>
              </div>
            </div>
            <div className={css.rightDataBox}>
              <ul>
                {companyName ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingPage.brandName" />
                    </span>
                    <span className={css.titleNormal}>{companyName}</span>
                  </li>
                ) : null}
                {teamName ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingDetailsForm.teamName" />
                    </span>
                    <span className={css.titleNormal}>{teamName}</span>
                  </li>
                ) : null}
                {athleteDescription ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingPage.description" />
                    </span>
                    <span className={css.titleNormal}>{athleteDescription}</span>
                  </li>
                ) : null}
                {brandDescription ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingPage.camapignDescription" />
                    </span>
                    <span className={css.titleNormal}>{brandDescription}</span>
                  </li>
                ) : null}
                {userLocation?.address ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingListingPage.location" />
                    </span>
                    <span className={css.titleNormal}>{userLocation?.address}</span>
                  </li>
                ) : null}
                {lifestyleInterest ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingListingPage.lifestyleInterest" />
                    </span>
                    <span className={css.titleNormal}>{lifestyle_Interest}</span>
                  </li>
                ) : null}
                {brandSponsorshipType ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingListingPage.sponsorshiptype" />
                    </span>
                    <span className={css.titleNormal}>{sponsorTypes}</span>
                  </li>
                ) : null}
                {activeIcons && activeIcons.length !== 0 ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingLocationForm.socialMediaChannels" />
                    </span>
                    <span className={css.titleNormal}>{icons}</span>
                  </li>
                ) : null}
                {selectedTermsAndConditions ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingAboutForm.termsAndConditionsLabel" />
                    </span>
                    <ul className={css.titleTerms} style={{ whiteSpace: 'pre-line' }}>
                      {termsAndConditions?.map(item => {
                        return (
                          <li>
                            {item}
                            <strong>
                              {item === 'Rights to image and name use for' ||
                                item === 'Oikeus kuviin ja nimen käyttöön'
                                ? monthslabel
                                : item === 'Must not delete social media post before'
                                  ? timeDuration_must_not_delete_social_media_post_before
                                  : null}
                            </strong>
                          </li>
                        );
                      })}
                    </ul>
                  </li>
                ) : null}
                {preferenceType ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="EditListingPage.lookingForLabel" />
                    </span>
                    <span className={css.titleNormal}>{preferenceTypeLabel}</span>
                  </li>
                ) : null}
                {sports ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage id="CustomExtendedDataField.prefferedSportLabel" />
                    </span>
                    <span className={css.titleNormal}>{sportsLabel}</span>
                  </li>
                ) : null}
                {price ? (
                  <li>
                    <span className={css.titleBold}>
                      <FormattedMessage
                        id={
                          listingType === BRAND
                            ? 'EditListingListingPage.value'
                            : 'EditListingPricingAndStockForm.pricePerProduct'
                        }
                      />
                    </span>
                    <span className={css.titleNormal}>{formattedPrice}</span>
                  </li>
                ) : null}
              </ul>
            </div>
          </div>
        ) : null}

        {listingType == ATHLETE ? (<div>
          <div className={css.profileBanner}>
            <div className={css.cardContent}>
              <div className={css.backgroundCircle}>
                <IconCollection name="backgroundCircle" />
              </div>
              <div className={css.textSection}>
                <div>
                  <h1 className={css.name}>{teamName ? (
                    <span className={css.teamNameHeading}>{teamName}</span>
                  ) : null}</h1>
                  <p className={css.details}>{brandDescription ? (
                    <span className={css.titleBolds}>{brandDescription}</span>
                  ) : null}</p>
                  <p className={css.details}>{userLocation ? (
                    <span className={css.titleBolds}>{userLocation?.address}</span>
                  ) : null}</p>
                  <p className={css.details}>{sports ? (
                    <span className={css.titleBolds}>{sportsLabel}</span>
                  ) : null}</p>
                </div>
                {/* Share Profile */}
                {
                  shareProfile && userType == ATHLETE ? <button id="copyBtn" className={css.copyProfile} onClick={() => CopyTextOnClipBoard(shareProfile, "copyBtn")}>
                    <FormattedMessage id="ListingPage.copyProfile" />
                  </button>
                    : null
                }
                {/* Contact btn */}
                {
                  userType == BRAND ? <button id="copyBtn" className={css.copyProfile} onClick={() => setInquiryModalOpen(true)}>
                    <FormattedMessage id="ListingPage.contactButton" />
                  </button>
                    : null
                }
              </div>
              <div className={css.imageSection}>
                {currentUser && userType == BRAND ? favoriteButton : null}
                <SectionHero
                  isAuthenticated={isAuthenticated}
                  title={title}
                  listing={currentListing}
                  isOwnListing={isOwnListing}
                  editParams={{
                    id: listingId.uuid,
                    slug: listingSlug,
                    type: listingPathParamType,
                    tab: listingTab,
                  }}
                  imageCarouselOpen={imageCarouselOpen}
                  onImageCarouselClose={() => setImageCarouselOpen(false)}
                  handleViewPhotosClick={handleViewPhotosClick}
                  onManageDisableScrolling={onManageDisableScrolling}
                />
              </div>
            </div>
          </div>

          <AboutListingSection shareProfile={shareProfile} currentListing={currentListing} athleteDescription={athleteDescription} />
          {lifestyleInterest ? <InterestListingSection lifestyle_Interest={lifestyleInterest_values} /> : null}

          <div className={css.athleteSocailBox}>
            <div className={css.athletePriceWrapper}>
              {logo_visibilityPrice ? (
                <div className={css.priceListBox}>
                  <div className={css.topContent}>
                    <div className={css.titlePrice}>
                      <FormattedMessage
                        id={
                          listingType === BRAND
                            ? 'EditListingListingPage.value'
                            : 'EditListingPage.logo'
                        }
                      />
                    </div>

                    <div>
                    {isShowPrice ? <button
                        // disabled={userType === ATHLETE}
                        // onClick={() => handleModal(logoPriceF, "logo_visibilityPrice")}
                        type="button"
                        className={css.titlePrice}
                      >
                         {formattedLogoVisibilityPrice}
                      </button>: null}
                    </div>
                  </div>
                  <div className={css.priceDescription}>
                    {logo_visibilityDescription ? logo_visibilityDescription : null}
                  </div>

                </div>
              ) : null}
              {appearancePrice ? (
                <div className={css.priceListBox}>
                  <div className={css.topContent}>
                    <div className={css.titlePrice}>
                      <FormattedMessage id="EditListingPage.apperance" />
                    </div>
                    <div>
                      {isShowPrice ? <button
                        // disabled={userType === ATHLETE}
                        // onClick={() => handleModal(appearancePriceF, "appearancePrice")}
                        type="button"
                        className={css.titlePrice}
                      >
                        {formattedApperancePrice}
                      </button> : null}
                    </div>

                  </div>
                  <div className={css.priceDescription}>
                    {appearanceDescription ? appearanceDescription : null}
                  </div>

                </div>
              ) : null}
              {social_mediaPrice ? (
                <div className={css.priceListBox}>
                  <div className={css.topContent}>
                    <div className={css.titlePrice}>
                      <FormattedMessage id="EditListinPage.socialMedia" />
                    </div>
                    <div>
                      {isShowPrice ? <button 
                        type="button"
                        className={css.titlePrice}
                      >
                        {formattedSocialMediaPrice}
                      </button> : null}
                    </div>

                  </div>
                  <div className={css.priceDescription}>
                    {' '}
                    {social_mediaDescription ? social_mediaDescription : null}
                  </div>

                </div>
              ) : null}
              {brand_ambassadorPrice ? (
                <div className={css.priceListBox}>
                  <div className={css.topContent}>
                    <div className={css.titlePrice}>
                      <FormattedMessage id="EditListingPage.ambassador" />
                    </div>
                    <div>
                      {isShowPrice ? <button
                        className={css.titlePrice} 
                        type="button"
                      >
                        {formattedBrandAmbassadorPrice}
                      </button> : null}
                    </div>
                  </div>
                  <div className={css.priceDescription}>
                    {brand_ambassadorDescription ? brand_ambassadorDescription : null}
                  </div>
                </div>
              ) : null}
              {eventsPrice ? (
                <div className={css.priceListBox}>
                  <div className={css.topContent}>
                    <div className={css.titlePrice}>
                      <FormattedMessage id="EditListingPage.event" />
                    </div>
                    <div>
                      {isShowPrice ? <button
                        className={css.titlePrice} 
                        type="button"
                      >
                        {formattedEventsPrice}
                      </button> : null}
                    </div>
                  </div>
                  <div className={css.eventWrapper}>
                    <span>{eventsName}</span>
                    <span>{moment(eventDate).format('DD/MM/YYYY')}</span>
                  </div>
                  <div className={css.priceDescription}>
                    {eventsDescription ? eventsDescription : null}
                  </div>
                </div>
              ) : null}
            </div>
            <div>
              {goals ? <div className={css.recordSection}>
                <h1><FormattedMessage id="ListingPage.goals" /></h1>
                <p>{goals}</p>
              </div> : null}
              {achievementsrecords ? <div className={classNames(css.recordSection, css.goalSection)}>
                <h1><FormattedMessage id="ListingPage.achievementsrecords" /></h1>
                <p>{achievementsrecords}</p>
              </div> : null}
            </div>

          </div>
          {Array.isArray(brandImages)&& brandImages[0].imageUrl !="" && brandImages.length > 0 ? <SponsorsListingSlider brandImages={brandImages}/> : null }
          <SectionAuthorMaybe
            title={title}
            className={css.sectionAuthor}
            listing={currentListing}
            authorDisplayName={authorDisplayName}
            onContactUser={onContactUser}
            config={config}
            intl={intl}
            listingType={listingType}
            userType={userType}
            priceArray={priceArraydynamic}
            isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
            onCloseInquiryModal={() => setInquiryModalOpen(false)}
            sendInquiryError={sendInquiryError}
            sendInquiryInProgress={sendInquiryInProgress}
            onSubmitInquiry={onSubmitInquiry}
            currentUser={currentUser}
            onManageDisableScrolling={onManageDisableScrolling}
          />
        </div>) : null}

      
        {listingType == PROMOTION ?
          <div className={css.promotionPageContainer}>
            <div className={css.bannerImage} onClick={handleImageClick}>
              <ResponsiveImage
                rootClassName={css.image}
                alt={"Promotional"}
                image={images[0]}
                variants={['scaled-large']}
              />
              {images.length > 1 ? <button className={css.viewAll}><FormattedMessage id="listingPage.viewAll" /></button> : null}
            </div>
            <Modal className={css.bannerImageModal} isOpen={modalOpen} onClose={closeModal} onManageDisableScrolling={onManageDisableScrolling}>
              <Slider {...sliderSettings}>
                {images.map((image, index) => (
                  <div key={index} className={css.modalImageWrapper}>
                    <ResponsiveImage
                      rootClassName={css.sliderImage}
                      alt={`Slide ${index}`}
                      image={image}
                      variants={['scaled-large']}
                    />
                  </div>
                ))}
              </Slider>
            </Modal>
            <div className={css.textContent}>
              <div className={css.leftContent}>
                {promotionTitle ? <div className={css.titleText}>{promotionTitle}</div>
                  : null}
                {promotionDetails ? <p className={css.subheading}>
                  {promotionDetails}
                </p>
                  : null}
                {promotionCode ? <p className={css.KoodillaText}><FormattedMessage id="listingPage.promotionCode" />: {promotionCode}</p> : null}
                {websiteLink ?
                  <ExternalLink href={websiteLink} className={css.button}>
                    <FormattedMessage id="listingPage.websiteLink" />
                  </ExternalLink>
                  : null}
                {promotionTerms ? <div className={css.terms}>
                  <h4 className={css.termTitle}><FormattedMessage id="listingPage.promotionTerms" />:</h4>
                  <p className={css.termDescription}>{promotionTerms}</p>
                </div> : null}
              </div>
              <div className={css.brandLogo}>
                <img
                  className={css.logo}
                  src={testImage}
                  alt="Brand Logo"
                />
                <span className={css.brandName}>Sbonssy Oy</span>
              </div>
            </div>
          </div>
          : null
        }

      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: arrayOf(propTypes.review),
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const showListingError = props.showListingError;
  const isVariant = props.params?.variant != null;
  const currentUser = props.currentUser;
  if (isForbiddenError(showListingError) && !isVariant && !currentUser) {
    // This can happen if private marketplace mode is active
    return (
      <NamedRedirect
        name="SignupPage"
        state={{ from: `${location.pathname}${location.search}${location.hash}` }}
      />
    );
  }

  const isPrivateMarketplace = config.accessControl.marketplace.private === true;
  const isUnauthorizedUser = currentUser && !isUserAuthorized(currentUser);
  const hasNoViewingRights = currentUser && !hasPermissionToViewData(currentUser);
  const hasUserPendingApprovalError = isErrorUserPendingApproval(showListingError);

  if ((isPrivateMarketplace && isUnauthorizedUser) || hasUserPendingApprovalError) {
    return (
      <NamedRedirect
        name="NoAccessPage"
        params={{ missingAccessRight: NO_ACCESS_PAGE_USER_PENDING_APPROVAL }}
      />
    );
  } else if (
    (hasNoViewingRights && isForbiddenError(showListingError)) ||
    isErrorNoViewingPermission(showListingError)
  ) {
    // If the user has no viewing rights, fetching anything but their own listings
    // will return a 403 error. If that happens, redirect to NoAccessPage.
    return (
      <NamedRedirect
        name="NoAccessPage"
        params={{ missingAccessRight: NO_ACCESS_PAGE_VIEW_LISTINGS }}
      />
    );
  }

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      showOwnListingsOnly={hasNoViewingRights}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    id,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    id,
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params => dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message, inquiryDetails) =>
    dispatch(sendInquiry(listing, message, inquiryDetails)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
