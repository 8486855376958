import React from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../ducks/auth.duck';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import css from './DeletedAccount.module.css'
import classNames from 'classnames';

export default function DeletedAccount() {
  const dispatch = useDispatch(); 
  const history = useHistory();
  const handleLogout = () => {
    dispatch(logout()).then(() => {
        if (typeof window !== 'undefined' && window.location) {
          history.push('/en/login');   
          }      
    }).catch(err => {
      console.error('Logout failed:', err);
    });
  };
  const handleContactUs = () => {
    dispatch(logout()).then(() => {
        if (typeof window !== 'undefined' && window.location) {
          history.push('/en/contact-us');   
          }      
    }).catch(err => {
      console.error('Logout failed:', err);
    });
  };

  return (
    <div className={css.container}>
     <div className={css.contents}>
      <p className={css.message}>
        <FormattedMessage id="deleteAccount.details" />
      </p>
      <button onClick={handleLogout} className={css.button}>
        <FormattedMessage id="deleteAccount.signupbutton" />
      </button>
      <button
        onClick={handleContactUs}
        className={classNames(css.button, css.contactButton)}
      >
        <FormattedMessage id="deleteAccount.ContactUs" />
      </button>
    </div>
   </div>
  );
}
