import React from 'react';
import css from './SponsorsListingSlider.module.css'
import Slider from "react-slick";
import demoImage from "../../assets/logo.png";
import { FormattedMessage } from 'react-intl';

const SponsorsListingSlider = (props) => {
  const {brandImages} = props|| {}; 
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 2,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                infinite: false,
                dots: true
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    return (
      <div className={css.sponsorsContainer}>
      <h1 className={css.title}><FormattedMessage id="SponsorsListing.heading" /></h1>
      <div className={css.sliderWrapper}>
        <Slider {...sliderSettings}>
          {/* Dynamically create slides based on the sponsors passed via props */}
          {Array.isArray(brandImages) && brandImages.length > 0 && brandImages.map((sponsor, index) => (
            <div key={index} className={css.card}>
              <img src={sponsor.imageUrl} alt={sponsor.sponsorsName} /> 
            </div>
          ))}
        </Slider>
      </div>
    </div>
    );
};

export default SponsorsListingSlider;