import merge from 'lodash/merge';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { fetchCurrentUser, currentUserShowSuccess } from '../../ducks/user.duck';

// ================ Action types ================ //

export const SAVE_CONTACT_DETAILS_REQUEST = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_REQUEST';
export const SAVE_CONTACT_DETAILS_SUCCESS = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_SUCCESS';
export const SAVE_CONTACT_DETAILS_CLEAR = 'app/ContactDetailsPage/SAVE_CONTACT_DETAILS_CLEAR';
// ================ Reducer ================ //
const initialState = {
    saveContactDetailsInProgress: false,
    contactDetailsChanged: false,
};

export default function reducer(state = initialState, action = {}) {
    const { type, payload } = action;
    switch (type) {
        case SAVE_CONTACT_DETAILS_REQUEST:
            return {
                ...state,
                saveContactDetailsInProgress: true,
                contactDetailsChanged: false,
            };
        case SAVE_CONTACT_DETAILS_SUCCESS:
            return { ...state, saveContactDetailsInProgress: false, contactDetailsChanged: true };
        case SAVE_CONTACT_DETAILS_CLEAR:
            return {
                ...state,
                saveContactDetailsInProgress: false,
                saveEmailError: null,
                savePhoneNumberError: null,
                contactDetailsChanged: false,
            };

        default:
            return state;
    }
}

// ================ Action creators ================ //

export const saveContactDetailsRequest = () => ({ type: SAVE_CONTACT_DETAILS_REQUEST });
export const saveContactDetailsSuccess = () => ({ type: SAVE_CONTACT_DETAILS_SUCCESS });
export const saveContactDetailsClear = () => ({ type: SAVE_CONTACT_DETAILS_CLEAR });

// ================ Thunks ================ //
/**
 * Update contact details, actions depend on which data has changed
 */
export const saveContactDetails = params => (dispatch, getState, sdk) => {
    dispatch(saveContactDetailsRequest());
    const { BankAccount, AthleteNumber, clubemailAddress, paymentPerformance } = params;
    sdk.currentUser.updateProfile({
        protectedData: {
            clubBankAccount: BankAccount,
            athleteNumber: AthleteNumber,
        },
        publicData: {
            paymentPerformance: paymentPerformance,
            clubEmailAddress: clubemailAddress,
        }
    }).then(res => {
        dispatch(saveContactDetailsSuccess()); 
    }).catch((error) => {
        console.log("Error", error);
    })
};
export const loadData = () => {
    return fetchCurrentUser();
};
