import React, { useState, useEffect } from 'react';
import css from './cookiePopupAcceptPage.module.css';
import { ExternalLink } from '../../components';
import { FormattedMessage } from 'react-intl';

const CookiePopupAccept = () => {

  const [showPopup, setShowPopup] = useState(false); 

  useEffect(() => {
    if (typeof window != "undefined") {
      const consent = localStorage.getItem('cookieConsent');
      if (!consent) {
        setShowPopup(true);
      }
    }
  }, []);
  const handleAccept = () => {
    if (typeof window != "undefined") {
      localStorage.setItem('cookieConsent', 'true');
      setShowPopup(false);
    }
  };
  return (
    showPopup && (
      <div className={css.popup}>
        <p className={css.message}>
          <FormattedMessage id="CookiepopupAccept.details" />
          {' '}
          <ExternalLink href="/privacy-policy" className={css.link}>
            <FormattedMessage id="CookiepopupAccept.PrivacyPolicy" />
          </ExternalLink>.
        </p>
        <button onClick={handleAccept} className={css.button}>
          <FormattedMessage id="CookiepopupAccept.button" />
        </button>
      </div>
    )
  );
};



export default CookiePopupAccept;

