import React from 'react';


const IconCollection = ({ name }) => {
    switch (name) {


        case 'facebook_icon':
            return (
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.5 0C5.62499 0 0 5.63504 0 12.5753C0 18.8504 4.57499 24.0587 10.55 25V16.2149H7.37499V12.5753H10.55V9.80171C10.55 6.65161 12.4125 4.91968 15.275 4.91968C16.6375 4.91968 18.0625 5.15813 18.0625 5.15813V8.25803H16.4875C14.9375 8.25803 14.45 9.2244 14.45 10.2159V12.5753H17.925L17.3625 16.2149H14.45V25C17.3955 24.5329 20.0777 23.024 22.0124 20.7456C23.947 18.4671 25.0067 15.5693 25 12.5753C25 5.63504 19.375 0 12.5 0Z" fill="black" />
                </svg>

            );

        case 'instagram_icon':
            return (
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.25 0H17.75C21.75 0 25 3.25 25 7.25V17.75C25 19.6728 24.2362 21.5169 22.8765 22.8765C21.5169 24.2362 19.6728 25 17.75 25H7.25C3.25 25 0 21.75 0 17.75V7.25C0 5.32718 0.763837 3.48311 2.12348 2.12348C3.48311 0.763837 5.32718 0 7.25 0ZM7 2.5C5.80653 2.5 4.66193 2.97411 3.81802 3.81802C2.97411 4.66193 2.5 5.80653 2.5 7V18C2.5 20.4875 4.5125 22.5 7 22.5H18C19.1935 22.5 20.3381 22.0259 21.182 21.182C22.0259 20.3381 22.5 19.1935 22.5 18V7C22.5 4.5125 20.4875 2.5 18 2.5H7ZM19.0625 4.375C19.4769 4.375 19.8743 4.53962 20.1674 4.83265C20.4604 5.12567 20.625 5.5231 20.625 5.9375C20.625 6.3519 20.4604 6.74933 20.1674 7.04235C19.8743 7.33538 19.4769 7.5 19.0625 7.5C18.6481 7.5 18.2507 7.33538 17.9576 7.04235C17.6646 6.74933 17.5 6.3519 17.5 5.9375C17.5 5.5231 17.6646 5.12567 17.9576 4.83265C18.2507 4.53962 18.6481 4.375 19.0625 4.375ZM12.5 6.25C14.1576 6.25 15.7473 6.90848 16.9194 8.08058C18.0915 9.25268 18.75 10.8424 18.75 12.5C18.75 14.1576 18.0915 15.7473 16.9194 16.9194C15.7473 18.0915 14.1576 18.75 12.5 18.75C10.8424 18.75 9.25268 18.0915 8.08058 16.9194C6.90848 15.7473 6.25 14.1576 6.25 12.5C6.25 10.8424 6.90848 9.25268 8.08058 8.08058C9.25268 6.90848 10.8424 6.25 12.5 6.25ZM12.5 8.75C11.5054 8.75 10.5516 9.14509 9.84835 9.84835C9.14509 10.5516 8.75 11.5054 8.75 12.5C8.75 13.4946 9.14509 14.4484 9.84835 15.1517C10.5516 15.8549 11.5054 16.25 12.5 16.25C13.4946 16.25 14.4484 15.8549 15.1517 15.1517C15.8549 14.4484 16.25 13.4946 16.25 12.5C16.25 11.5054 15.8549 10.5516 15.1517 9.84835C14.4484 9.14509 13.4946 8.75 12.5 8.75Z" fill="black" />
                </svg>

            );

        case 'youtube_icon':
            return (
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10 17.8571L16.4875 12.5L10 7.14286V17.8571ZM24.45 3.875C24.6125 4.71429 24.725 5.83928 24.8 7.26786C24.8875 8.69643 24.925 9.92857 24.925 11L25 12.5C25 16.4107 24.8 19.2857 24.45 21.125C24.1375 22.7321 23.4125 23.7679 22.2875 24.2143C21.7 24.4464 20.625 24.6071 18.975 24.7143C17.35 24.8393 15.8625 24.8929 14.4875 24.8929L12.5 25C7.2625 25 4 24.7143 2.7125 24.2143C1.5875 23.7679 0.8625 22.7321 0.55 21.125C0.3875 20.2857 0.275 19.1607 0.2 17.7321C0.1125 16.3036 0.0749999 15.0714 0.0749999 14L0 12.5C0 8.58929 0.2 5.71429 0.55 3.875C0.8625 2.26786 1.5875 1.23214 2.7125 0.785714C3.3 0.553572 4.375 0.392857 6.025 0.285714C7.65 0.160714 9.1375 0.107143 10.5125 0.107143L12.5 0C17.7375 0 21 0.285714 22.2875 0.785714C23.4125 1.23214 24.1375 2.26786 24.45 3.875Z" fill="black" />
                </svg>
            );
        case 'linkedin_icon':
            return (
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.2222 0C22.9589 0 23.6655 0.292658 24.1864 0.813592C24.7073 1.33453 25 2.04107 25 2.77778V22.2222C25 22.9589 24.7073 23.6655 24.1864 24.1864C23.6655 24.7073 22.9589 25 22.2222 25H2.77778C2.04107 25 1.33453 24.7073 0.813592 24.1864C0.292658 23.6655 0 22.9589 0 22.2222V2.77778C0 2.04107 0.292658 1.33453 0.813592 0.813592C1.33453 0.292658 2.04107 0 2.77778 0H22.2222ZM21.5278 21.5278V14.1667C21.5278 12.9658 21.0507 11.8142 20.2016 10.965C19.3525 10.1159 18.2008 9.63889 17 9.63889C15.8194 9.63889 14.4444 10.3611 13.7778 11.4444V9.90278H9.90278V21.5278H13.7778V14.6806C13.7778 13.6111 14.6389 12.7361 15.7083 12.7361C16.224 12.7361 16.7186 12.941 17.0833 13.3056C17.4479 13.6703 17.6528 14.1649 17.6528 14.6806V21.5278H21.5278ZM5.38889 7.72222C6.00773 7.72222 6.60122 7.47639 7.03881 7.03881C7.47639 6.60122 7.72222 6.00773 7.72222 5.38889C7.72222 4.09722 6.68056 3.04167 5.38889 3.04167C4.76637 3.04167 4.16934 3.28896 3.72915 3.72915C3.28896 4.16934 3.04167 4.76637 3.04167 5.38889C3.04167 6.68056 4.09722 7.72222 5.38889 7.72222ZM7.31944 21.5278V9.90278H3.47222V21.5278H7.31944Z" fill="black" />
                </svg>

            );
        case 'backgroundCircle':
            return (
                <svg width="437" height="622" viewBox="0 0 437 622" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="0.5" cy="309.5" r="436.5" fill="#F97C30" />
                </svg>
            );
        case 'love_icon':
            return (
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="15" fill="white" />
                    <path d="M15.004 23C14.7377 23 14.4993 22.898 14.3164 22.7097L9.05862 17.575C9.02285 17.5514 8.93145 17.4651 8.8162 17.3435C8.71287 17.2337 8.54595 17.0375 8.31943 16.7512C8.0929 16.4688 7.89021 16.1706 7.71138 15.8686C7.52459 15.5587 7.36165 15.1821 7.21858 14.7506C7.07154 14.3034 7 13.8602 7 13.4365C7 12.0675 7.40537 10.977 8.2002 10.1886C8.99901 9.40011 10.0959 9 11.4749 9C11.8684 9 12.2737 9.07061 12.687 9.2079C13.0845 9.34127 13.462 9.52564 13.8038 9.75315C14.1297 9.9689 14.4118 10.1729 14.6423 10.3651C14.7655 10.4592 14.8848 10.5651 15 10.6711C15.1153 10.5651 15.2384 10.4592 15.3577 10.3651C15.5921 10.1729 15.8703 9.9689 16.1962 9.75315C16.538 9.52564 16.9156 9.34127 17.313 9.2079C17.7223 9.07061 18.1277 9 18.5251 9C19.9001 9 21.001 9.40011 21.7998 10.1886C22.5946 10.977 23 12.0675 23 13.4365C23 14.8055 22.3085 16.202 20.9414 17.5828L15.6836 22.7136C15.5007 22.8941 15.2663 22.9961 15 22.9961L15.004 23Z" fill="#F26915" />
                </svg>
            );
        default:
            return <></>;
    }
};

export default IconCollection;