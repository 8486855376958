/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ACCOUNT_SETTINGS_PAGES } from '../../../../routing/routeConfiguration';
import { FormattedMessage } from '../../../../util/reactIntl';
import { ATHLETE, BRAND, propTypes, SPORTTEAM } from '../../../../util/types';
import { ensureCurrentUser, ensureOwnListing } from '../../../../util/data';
import {
  AvatarLarge,
  ExternalLink,
  InlineTextButton,
  Modal,
  NamedLink,
  NotificationBadge,
} from '../../../../components';

import css from './TopbarMobileMenu.module.css';
import { getUserType } from '../../../../util/helper';
import IconCard from '../../../../components/SavedCardDetails/IconCard/IconCard';
import { createSlug } from '../../../../util/urlHelpers';

// const CustomLinkComponent = ({ linkConfig, currentPage }) => {
//   const { group, text, type, href, route } = linkConfig;
//   const getCurrentPageClass = page => {
//     const hasPageName = name => currentPage?.indexOf(name) === 0;
//     const isCMSPage = pageId => hasPageName('CMSPage') && currentPage === `${page}:${pageId}`;
//     const isInboxPage = tab => hasPageName('InboxPage') && currentPage === `${page}:${tab}`;
//     const isCurrentPage = currentPage === page;

//     return isCMSPage(route?.params?.pageId) || isInboxPage(route?.params?.tab) || isCurrentPage
//       ? css.currentPage
//       : null;
//   };

//   // Note: if the config contains 'route' keyword,
//   // then in-app linking config has been resolved already.
//   if (type === 'internal' && route) {
//     // Internal link
//     const { name, params, to } = route || {};
//     const className = classNames(css.navigationLink, getCurrentPageClass(name));
//     return (
//       <NamedLink name={name} params={params} to={to} className={className}>
//         <span className={css.menuItemBorder} />
//         {text}
//       </NamedLink>
//     );
//   }
//   return (
//     <ExternalLink href={href} className={css.navigationLink}>
//       <span className={css.menuItemBorder} />
//       {text}
//     </ExternalLink>
//   );
// };

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUser,
    notificationCount,
    customLinks,
    onLogout,
    currentUserListing,
    onManageDisableScrolling,
  } = props;

  const user = ensureCurrentUser(currentUser);
  const listing = ensureOwnListing(currentUserListing);
  const userType = getUserType(currentUser);

  const [mounted, setMounted] = useState(false);
  const [menuState, setMenuState] = useState(false);
  const [isProfileModal, setIsProfileModal] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('Finnish');
  const draftId = '00000000-0000-0000-0000-000000000000';
  const draftSlug = 'draft';
  useEffect(() => {
    setMounted(true);
    const storedLanguage = localStorage.getItem('language');
    if (!localStorage.getItem('rendered')) {
      localStorage.setItem('language', 'Finnish');
      localStorage.setItem('rendered', 'Finnish');
      setLanguage('Finnish');
    }
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, []);

  const setLanguage = lg => {
    typeof window != 'undefined' && localStorage.setItem('language', lg);
    typeof window != 'undefined' && window.location.reload();
  };

  const handleLanguageChange = lg => {
    setLanguage(lg);
    setSelectedLanguage(lg);
    setMenuState(false);
  };

  const checkCurrentUserPublicData = currentUser => {
    const isBio = currentUser.attributes.profile?.bio;
    const {
      companyName,
      website,
      myCompanyInterestValues,
    } = currentUser?.attributes?.profile?.publicData || {};
    const allFieldsExist =
      companyName && website && myCompanyInterestValues && myCompanyInterestValues.length > 0;
    if (allFieldsExist && isBio) {
      return true;
    } else {
      return false;
    }
  };

  const isUserProfileCompleted =
    currentUser && currentUser?.id && checkCurrentUserPublicData(currentUser);
  // const language = typeof window !== 'undefined' && localStorage.getItem('language');

  // const extraLinks = customLinks.map((linkConfig, index) => {
  //   return (
  //     <CustomLinkComponent
  //       key={`${linkConfig.text}_${index}`}
  //       linkConfig={linkConfig}
  //       currentPage={currentPage}
  //     />
  //   );
  // });

  if (!isAuthenticated) {
    const signup = (
      <NamedLink name="SignupPage" className={css.signupLink}>
        <FormattedMessage id="TopbarMobileMenu.signupLink" />
      </NamedLink>
    );

    const login = (
      <NamedLink name="LoginPage" className={css.loginLink}>
        <FormattedMessage id="TopbarMobileMenu.loginLink" />
      </NamedLink>
    );

    const signupOrLogin = (
      <span className={css.authenticationLinks}>
        <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
      </span>
    );
    return (
      <div className={css.root}>
        <div className={css.content}>
          <div className={css.authenticationGreeting}>
            <FormattedMessage
              id="TopbarMobileMenu.unauthorizedGreeting"
              values={{ lineBreak: <br />, signupOrLogin }}
            />

            <div className={isAuthenticated ? css.navlinksNotAuthenticated : css.navLinks}>
              <div className={css.coursetab}>
                <div className={css.logoutClick} onClick={() => setMenuState(!menuState)}>
                  <IconCard brand="globe" /> {selectedLanguage === 'Finnish' ? 'FI' : 'EN'}
                </div>
                {menuState && (
                  <div className={css.courseDropDown}>
                    <div className={css.topbarLink} onClick={() => handleLanguageChange('Finnish')}>
                      <span className={css.topbarLinkLabel}>FI</span>
                    </div>
                    <div className={css.topbarLink} onClick={() => handleLanguageChange('English')}>
                      <span className={css.topbarLinkLabel}>EN</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className={css.describingWrapper}>
              <div className={css.homelinks}>
              </div>
              <div className={css.homelinks}>
              </div>
            </div>
          </div>
        </div>
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="NewListingPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      </div>
    );
  }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };

  const completeProfileLink = manageProfileLink => {
    const namedLinkClass = manageProfileLink
      ? classNames(css.menuLink, currentPageClass('ManageListingsPage'))
      : css.menuLink;

    const namedLinkChild = manageProfileLink ? (
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.completeYourProfile" />
      </span>
    ) : (
      <span className={css.createListing}>
        <FormattedMessage id="TopbarDesktop.completeYourProfile" />
      </span>
    );

    if (user && userType === ATHLETE) {
      if (listing && listing.attributes.state && listing.attributes.state === 'draft') {
        const id = listing.id.uuid;
        const { title = '' } = listing.attributes;
        const slug = createSlug(title);

        return (
          <NamedLink
            className={css.listingPage}
            name="EditListingPage"
            params={{ id, slug, type: 'draft', tab: 'details' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (!(currentUserHasListings ?? true)) {
        const draftId = '00000000-0000-0000-0000-000000000000';
        const draftSlug = 'draft';
        return (
          <NamedLink
            className={namedLinkClass}
            name="EditListingPage"
            params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      } else if (
        listing &&
        listing.attributes.state &&
        listing.attributes.state === 'pendingApproval'
      ) {
        const id = listing.id.uuid;
        const { title = '' } = listing.attributes;
        const slug = createSlug(title);
        return (
          <NamedLink
            className={namedLinkClass}
            name="ListingPageVariant"
            params={{ id, slug, variant: 'pending-approval' }}
          >
            {namedLinkChild}
          </NamedLink>
        );
      }
    }

    return <></>;
  };

  const editStylistProfileLink =
    userType === ATHLETE && listing.id ? (
      <NamedLink
        name="EditListingPage"
        className={classNames(css.navigationLink, currentPageClass('EditListingPage'))}
        params={{
          slug: createSlug(listing.attributes.title || ''),
          id: listing.id.uuid,
          type: 'edit',
          tab: 'details',
        }}
      >
        <FormattedMessage id="TopbarDesktop.editProfile" />
      </NamedLink>
    ) : (
      <></>
    );

  let CampaignsLink;
  let AthletesLink;
  if (isAuthenticated) {
    if (userType === ATHLETE || userType == SPORTTEAM) {
      CampaignsLink = (
        <NamedLink className={css.inboxLink} name="SearchPage">
          <span className={css.inbox}>
            <FormattedMessage id="TopbarDesktop.campaigns" />
          </span>
        </NamedLink>
      );
    } else {
      AthletesLink = (
        <div>
          {isUserProfileCompleted ? (
            <NamedLink className={css.inboxLink} name="SearchPage">
              <span className={css.inbox}>
                <FormattedMessage id="TopbarDesktop.marketplace" />
              </span>
            </NamedLink>
          ) : (
            <div onClick={() => setIsProfileModal(true)} className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.marketplace" />
            </div>
          )}
          <Modal
            id="ProfileRouteModal"
            isOpen={isProfileModal}
            onClose={() => setIsProfileModal(false)}
            onManageDisableScrolling={onManageDisableScrolling}
          >
            <div className={css.termsWrapper}>
              <h4>
                <FormattedMessage id="TopbarDesktop.pleaseCompleteProfile" />
              </h4>
              <NamedLink className={css.createNewListingLink} name="ProfileSettingsPage">
                <FormattedMessage id="TopbarDesktop.completeYour" />
              </NamedLink>
            </div>
          </Modal>
        </div>
      );
    }
  }



  return (
    <div className={css.root}>
      <AvatarLarge className={css.avatar} user={currentUser} />

      <div className={css.content}>
        <span className={css.greeting}>
          <FormattedMessage id="TopbarMobileMenu.greeting" values={{ displayName }} />
        </span>
        <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
          <FormattedMessage id="TopbarMobileMenu.logoutLink" />
        </InlineTextButton>

        <div className={isAuthenticated ? css.navlinksNotAuthenticated : css.navLinks}>
          <div className={css.coursetab}>
            <div className={css.loginClick} onClick={() => setMenuState(!menuState)}>
              <IconCard brand="globe" /> {selectedLanguage === 'Finnish' ? 'FI' : 'EN'}
            </div>
            {menuState && (
              <div className={css.courseDropDown}>
                <div className={css.topbarLink} onClick={() => handleLanguageChange('Finnish')}>
                  <span className={css.topbarLinkLabel}>FI</span>
                </div>
                <div className={css.topbarLink} onClick={() => handleLanguageChange('English')}>
                  <span className={css.topbarLinkLabel}>EN</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {userType === ATHLETE ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
            name="ManageListingsPage"
          >
            {completeProfileLink(true)}
          </NamedLink>
        ) : null}
        <NamedLink
          className={classNames(css.inbox, currentPageClass('InboxPage'))}
          name="InboxPage"
          params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
        >
          <FormattedMessage id="TopbarMobileMenu.inboxLink" />
          {notificationCountBadge}
        </NamedLink>

        {/* {user && userType === BRAND ? (
        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('ManageListingsPage'))}
          name="ManageListingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.yourListingsLink" />
        </NamedLink>
      ) : null} */}
        {user && userType == ATHLETE ? (
          <></>
        ) : (
          <>
            <NamedLink
              className={classNames(css.navigationLink, currentPageClass('ProfileSettingsPage'))}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
            <NamedLink
              className={classNames(css.yourCampaignLink, currentPageClass('ManageListingsPage'))}
              name="ManageListingsPage"
            >
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </>
        )}
        {editStylistProfileLink}

        {userType == SPORTTEAM ? (
          <NamedLink
            className={classNames(css.navigationLink, currentPageClass('EditListingPage'))}
            name="EditListingPage"
            params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'details' }}
          >
            <span className={css.inbox}>
              <FormattedMessage id="TopbarDesktop.createListingForSportTeam" />
            </span>
          </NamedLink>
        ) : null}

        <NamedLink
          className={classNames(css.navigationLink, currentPageClass('AccountSettingsPage'))}
          name="AccountSettingsPage"
        >
          <FormattedMessage id="TopbarMobileMenu.accountSettingsLink" />
        </NamedLink>

        {/* <div className={css.spacer} /> */}

      </div>
      {userType === BRAND ? (
        <div className={css.footer}>
          <NamedLink className={css.createNewListingLink} name="CampaignDescriptionPage">
            <FormattedMessage id="TopbarMobileMenu.newListingLink" />
          </NamedLink>
        </div>
      ) : null}
      <div className={css.campaignsMenuLink}>
        {CampaignsLink}
        {AthletesLink}
      </div>


      {/* <div>
      <NamedLink
        className={classNames(css.yourCampaignLink, currentPageClass('ManageListingsPage'))}
        name="ManageListingsPage"
      >
        {userType == BRAND ? (
          <FormattedMessage id="TopbarDesktop.yourListingsLink" />
        ) : userType == ATHLETE ? (
          <FormattedMessage id="TopbarDesktop.yourListingsLinkAthlete" />
        ) : (
          <FormattedMessage id="TopbarDesktop.yourListingsLinkSportTeam" />
        )}
      </NamedLink>
    </div> */}
    </div>
  );
};

TopbarMobileMenu.defaultProps = { currentUser: null, notificationCount: 0, currentPage: null };

const { bool, func, number, string } = PropTypes;

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
