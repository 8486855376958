import React from 'react';
import Slider from "react-slick";
import css from './AboutListingSection.module.css';
import demoImage from '../../assets/biketribe-brandImage-1500.jpg';
import IconCollection from '../../components/IconCollection/IconCollection';
import { FormattedMessage } from 'react-intl';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const AboutListingSection = (props) => {
    const { athleteDescription, shareProfile, currentListing } = props;
    const { images } = currentListing || [];
    const sliderSettings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // Encode URL
    const encodeUrl = (str) => encodeURIComponent(str);
    const rootUrl = process.env.REACT_APP_MARKETPLACE_ROOT_URL; 
    // const url = `${rootUrl}/l/${shareProfile || "default-profile"}`;
    const url = rootUrl + "/l/" + shareProfile
    
    const title = "Sbonssy";
    const description = "Sbonssy profile";
    const image = "https://example.com/your-image.png"; // Replace with your actual image URL 
    // Social Media Share URLs
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeUrl(url)}`;
    const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeUrl(url)}&title=${encodeUrl(title)}&summary=${encodeUrl(description)}&source=`;
    const youtubeUrl = `https://www.youtube.com/watch?v=PcPpOczSYsw`;

    const InstagramShareButton = ({ url }) => {
        const handleClick = () => {
            window.open(`https://www.instagram.com/${encodeURIComponent(url)}`, '_blank');
        }; 
        return (
            <button onClick={handleClick} style={{ border: "none", cursor: "pointer" }}>
                <IconCollection name="instagram_icon" />
            </button>
        );
    };

    return (
        <HelmetProvider>
            <Helmet>
                {/* Dynamic Meta Tags */}
                <title>{title}</title>
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={url} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={image} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:url" content={url} />
                <meta name="twitter:image" content={image} />
            </Helmet>
            <div className={css.aboutContainer}>
                <div className={css.aboutContent}>
                    {/* Left Content */}
                    <div className={css.textContent}>
                        <h2><FormattedMessage id="EditListingPage.description" /></h2>
                        <p>{athleteDescription}</p>
                        {/* Social Media Icons */}
                        <div className={css.socialIcons}>
                            <a href={linkedinUrl} target="_blank" rel="noopener noreferrer" className={css.link}>
                                <IconCollection name="linkedin_icon" />
                            </a>
                            <a href={youtubeUrl} target="_blank" rel="noopener noreferrer" className={css.link}>
                                <IconCollection name="youtube_icon" />
                            </a>
                            <a href={facebookUrl} target="_blank" rel="noopener noreferrer" className={css.link}>
                                <IconCollection name="facebook_icon" />
                            </a>
                            <InstagramShareButton url={url} />
                        </div>
                    </div>

                    {/* Right Content: Slick Slider */}
                    <div className={css.slider}>
                        <Slider {...sliderSettings}>
                            {images?.map((image, index) => (
                                <div key={image.id.uuid || index} className={css.cardImage}>
                                    <img src={image.attributes?.variants?.['listing-card-2x']?.url || demoImage} alt={`Slide ${index + 1}`} />
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
        </HelmetProvider>
    );
};

export default AboutListingSection;
