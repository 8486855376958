import React from 'react';
import css from './InterestListingSection.module.css'
import { FormattedMessage } from 'react-intl';

const InterestListingSection = (props) => {
    const { lifestyle_Interest } = props 
    
    const interests = lifestyle_Interest.map((item, index) => ({
        id: index + 1,
        text: item,
    }));
    return (
        <div className={css.container}>
            <div className={css.content}>
                <h2 className={css.title}> 
                    <FormattedMessage id="EditListingListingPage.lifestyleInterest" />
                    </h2>
                <div className={css.grid}>
                    {interests.map(({ id, text }) => (
                        <div key={id} className={css.item}>
                            <div className={css.circle}>{id}</div>
                            <span className={css.itemText}>{text}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default InterestListingSection;