import React from 'react';
import { string, func, bool, oneOfType } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { ATHLETE, PROMOTION, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';

import { AspectRatioWrapper, Button, NamedLink, ResponsiveImage, SecondaryButton } from '../../components';

import css from './ListingCard.module.css';
import IconCollection from '../IconCollection/IconCollection';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
    currentUser,
    onToggleFavorites,
    showfav = false
  } = props;
  const { listingType, sports } = listing?.attributes?.publicData || {};
  const isFavorite = currentUser?.attributes.profile.privateData.favorites?.includes(listing.id.uuid) || false;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage = currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
      onMouseEnter: () => setActiveListing(currentListing.id),
      onMouseLeave: () => setActiveListing(null),
    }
    : null;

  const favoriteButton = isFavorite ? (
    <SecondaryButton
      className={css.favoriteButton}
      onClick={(event) => {
        event.preventDefault();
        onToggleFavorites(listing.id.uuid, isFavorite);
      }}
    >
      <IconCollection name="love_icon" />
    </SecondaryButton>
  ) : (
    <Button
      className={classNames(css.favoriteButton, css.notActiveFavorite)}
      onClick={(event) => {
        event.stopPropagation();
        onToggleFavorites(listing.id.uuid, isFavorite);
      }}
    >
      <IconCollection name="love_icon" />
    </Button>
  );

  
  const sportsShowDetails = Array.isArray(sports) && sports.length ? sports.map(item =>intl.formatMessage({ id: `enum.${item}` })) : [];
  

  return (
    <div>
      {
        listingType === PROMOTION ?
          <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
            <div className={css.promotionGrid}>
              <AspectRatioWrapper
                className={css.aspectRatioWrapper}
                width={aspectWidth}
                height={aspectHeight}
                {...setActivePropsMaybe}
              >
                <LazyImage
                  rootClassName={css.rootForImage}
                  alt={title}
                  image={firstImage}
                  variants={variants}
                  sizes={renderSizes}
                />
              </AspectRatioWrapper>
              <div className={css.info}>
                <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />
                <div className={css.mainInfo}>
                  <div className={css.title}>
                    {richText(title, {
                      longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                      longWordClass: css.longWord,
                    })}
                  </div>
                  {showAuthorInfo ? (
                    <div className={css.authorInfo}>
                      <FormattedMessage id="ListingCard.author" values={{ authorName }} />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </NamedLink>
          : <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
            <AspectRatioWrapper
              className={css.aspectRatioWrapper}
              width={aspectWidth}
              height={aspectHeight}
              {...setActivePropsMaybe}
            >
              <LazyImage
                rootClassName={css.rootForImage}
                alt={title}
                image={firstImage}
                variants={variants}
                sizes={renderSizes}
              />
            </AspectRatioWrapper>
            <div className={css.info}>
              {listingType === ATHLETE ? null : <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} />}
              <div className={css.mainInfo}>
                <div className={css.title}>
                  {richText(title, {
                    longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
                    longWordClass: css.longWord,
                  })}
                </div>
                {showAuthorInfo && listingType !== ATHLETE ? (
                  <div className={css.authorInfo}>
                    <FormattedMessage id="ListingCard.author" values={{ authorName }} />
                  </div>
                ) : null}
                {listingType === ATHLETE ? (
                  <div className={css.authorInfo}>
                    {sportsShowDetails.length ?
                      sportsShowDetails.map((item, index) => (
                        <span key={index}>
                          {item}
                          {index < sportsShowDetails.length - 1 && ", "}
                        </span>
                      ))
                      : null
                    }
                  </div>
                ) : null}

              </div>
            </div>
            {showfav ? favoriteButton : null}
          </NamedLink>
      }

    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]).isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
