import React from 'react';
import { useConfiguration } from '../../context/configurationContext';
import loadable from '@loadable/component';

const SectionBuilder = loadable(
  () => import(/* webpackChunkName: "SectionBuilder" */ '../PageBuilder/PageBuilder'),
  {
    resolveComponent: components => components.SectionBuilder,
  }
);

const FooterComponent = () => {
  const { footer = {}, topbar } = useConfiguration();

  // If footer asset is not set, let's not render Footer at all.
  if (Object.keys(footer).length === 0) {
    return null;
  }
  const  language = typeof window !== 'undefined' && localStorage.getItem('language'); 

  const fiFooter={  
    blocks:[
       {blockType:"footerBlock",blockId:"general",
     text: {content: '- [Meistä](/tietoa)\n- [Ota yhteyttä](/ota-yhteytta)',fieldType: 'markdown'}},
     
      {blockType:"footerBlock" ,blockId:"terms-and-privacy", 
       text: {content: "[Käyttöehdot](/kayttoehdot)\n[Tietosuojakäytäntö](/yksityisyydensuoja)\n- [FAQ](/ukk)", fieldType:'markdown'}}, 
      ], copyright:{ content:"© 2024 Sbonssy. Kaikki oikeudet pidätetään." ,fieldType:"text"},
       numberOfColumns:2, 
       slogan: {content: "Linkki brändien ja urheilijoiden välillä.", fieldType:"text" }, 
       socialMediaLinks:[ 
        { 
          link: { fieldType :"socialMediaLink",platform: "facebook", url: "https://www.facebook.com/sbonssyfi/"}, blockType:"socialMediaLink" 
        },
           {
             link: { fieldType :"socialMediaLink",platform: "linkedin", url: "https://www.linkedin.com/company/sbonssy/"},
            blockType:"socialMediaLink" 
          }, 
          { 
            link: { fieldType :"socialMediaLink",platform: "instagram", url: "https://www.instagram.com/sbonssy/"}, 
            blockType:"socialMediaLink"
           }, 
          ]
       }
     const newFooter = language === "Finnish" ? fiFooter : footer;
  // The footer asset does not specify sectionId or sectionType. However, the SectionBuilder
  // expects sectionId and sectionType in order to identify the section. We add those
  // attributes here before passing the asset to SectionBuilder.
  const footerSection = {
    ...newFooter,
    sectionId: 'footer',
    sectionType: 'footer',
    linkLogoToExternalSite: topbar?.logoLink,
  };

  return <SectionBuilder sections={[footerSection]} />;
};

// NOTE: if you want to add dynamic data to FooterComponent,
//       you could just connect this FooterContainer to Redux Store
//
// const mapStateToProps = state => {
//   const { currentUser } = state.user;
//   return { currentUser };
// };
// const FooterContainer = compose(connect(mapStateToProps))(FooterComponent);
// export default FooterContainer;

export default FooterComponent;
