import React from 'react';
import { FormattedMessage } from '../../util/reactIntl';
import { INQUIRY_PROCESS_NAME, resolveLatestProcessName } from '../../transactions/transaction';

import { Heading, Modal, NamedLink } from '../../components';
import UserCard from './UserCard/UserCard';
import InquiryForm from './InquiryForm/InquiryForm';
import { types as sdkTypes } from '../../util/sdkLoader';
const { UUID, Money } = sdkTypes;
import { convertMoneyToNumber, formatMoney } from '../../util/currency';
import css from './ListingPage.module.css';
import { ATHLETE, BRAND, SPORTTEAM } from '../../util/types';

const SectionAuthorMaybe = props => {
  const {
    title,
    listing,
    authorDisplayName,
    onContactUser,
    isInquiryModalOpen,
    onCloseInquiryModal,
    sendInquiryError,
    sendInquiryInProgress,
    onSubmitInquiry,
    currentUser,
    listingType,
    onManageDisableScrolling,
    priceArray,
    userType,
    config,
    intl,
  } = props;
  if (!listing.author) {
    return null;
  }
  const transactionProcessAlias = listing?.attributes?.publicData?.transactionProcessAlias || '';
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const isInquiryProcess = processName === INQUIRY_PROCESS_NAME;
  const brandPrice = listing?.attributes?.publicData?.brandPrice;
  const { athleteNumber, clubBankAccount } = currentUser?.attributes?.profile?.protectedData || {};
  const { clubEmailAddress } = currentUser?.attributes?.profile?.publicData || {};
  const { stripeConnected } = currentUser?.attributes || {};

  const initialValues = {
    deliverableItems: [],
    partnershipStartLength: [],
    partnershipLength: '',
    inquiryPrice: new Money(brandPrice * 100, listing?.attributes?.price?.currency),
    paymentPreference: '',
  };

  return (
    <div id="author" className={css.sectionAuthor}>
      {/* <Heading as="h2" rootClassName={css.sectionHeadingWithExtraMargin}>
        <FormattedMessage id="ListingPage.aboutProviderTitle" />
      </Heading> */}
      <UserCard
        userType={userType}
        user={listing.author}
        currentUser={currentUser}
        onContactUser={onContactUser}
        listingType={listingType}
        showContact={!isInquiryProcess}
      />
      {/* {[BRAND].includes(userType) || ([ATHLETE, SPORTTEAM].includes(userType) && (stripeConnected || clubBankAccount)) ? (
        <Modal
          id="ListingPage.inquiry"
          contentClassName={css.inquiryModalContent}
          isOpen={isInquiryModalOpen}
          onClose={onCloseInquiryModal}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
        >
          {isInquiryModalOpen ? (
            <InquiryForm
              currentUser={currentUser}
              priceArray={priceArray}
              userType={userType}
              initialValues={listingType === BRAND ? initialValues : isInquiryModalOpen}
              className={css.inquiryForm}
              submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
              listingTitle={title}
              listingType={listingType}
              authorDisplayName={authorDisplayName}
              sendInquiryError={sendInquiryError}
              onSubmit={onSubmitInquiry}
              inProgress={sendInquiryInProgress}
              listing={listing}
              config={config}
            />
          ) : null}
        </Modal>
      ) : (
        <Modal
          id="ListingPage.inquiry"
          contentClassName={css.inquiryModalContent}
          isOpen={isInquiryModalOpen}
          onClose={onCloseInquiryModal}
          usePortal
          onManageDisableScrolling={onManageDisableScrolling}
          className={css.inquiryModelStripe}
        >
          {[ATHLETE, SPORTTEAM].includes(userType) ? (
            <label><FormattedMessage id="SectionAuthor.athleteStripeTitle" /></label>
          ) : (
            <label><FormattedMessage id="SectionAuthor.sportsTeamStripeTitle" /></label>
          )}
          <div className={css.modelStripeButton}>
            <NamedLink name="StripePayoutPage">
              <label><FormattedMessage id="SectionAuthor.connectStripe" /></label>
            </NamedLink>
            {[ATHLETE, SPORTTEAM].includes(userType) ? (
              <NamedLink name="ClubPaymentPage">
                <label><FormattedMessage id="SectionAuthor.connectSportsFund" /></label>
              </NamedLink>
            ) : null}
          </div>
        </Modal>
      )} */}

      <Modal
        id="ListingPage.inquiry"
        contentClassName={css.inquiryModalContent}
        isOpen={isInquiryModalOpen}
        onClose={onCloseInquiryModal}
        usePortal
        onManageDisableScrolling={onManageDisableScrolling}
      >
        {isInquiryModalOpen ? (
          <InquiryForm
            currentUser={currentUser}
            priceArray={priceArray}
            userType={userType}
            initialValues={listingType === BRAND ? initialValues : isInquiryModalOpen}
            className={css.inquiryForm}
            submitButtonWrapperClassName={css.inquirySubmitButtonWrapper}
            listingTitle={title}
            listingType={listingType}
            authorDisplayName={authorDisplayName}
            sendInquiryError={sendInquiryError}
            onSubmit={onSubmitInquiry}
            inProgress={sendInquiryInProgress}
            listing={listing}
            config={config}
          />
        ) : null}
      </Modal>
    </div>
  );
};

export default SectionAuthorMaybe;
